@keyframes heroFadeIn {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hero {
  align-items: center;
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: -1;

  &__heading {
    animation: heroFadeIn 1s cubic-bezier(0.63, 0.01, 0.4, 1.01) forwards;
    color: #FDBB15;
    font-family: GTSuperText, serif;
    font-size: 5.6vw;
    font-weight: bold;
    letter-spacing: -.25px;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 50px;
    margin-top: -4%;
    opacity: 1;
    transform: translateY(50%);
    width: 85%;
    z-index: 1;

    @include small {
      font-size: 40px;
      margin-left: 23px;
      margin-top: -13%;
    }

    @include xsmall {
      font-size: 35px;
    }

    &::selection,
    &::-moz-selection {
      background-color: #eee; /* WebKit/Blink Browsers */
    }
  }
}