.sidebar {
  background-color: #fff;
  border-left: 1px solid #000;
  color: #000;
  height: 100vh;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.81, 0, 0.23, 0.99);
  width: 41vw;
  z-index: 102;

  @include medium {
    width: 600px;
  }

  @include small {
    width: 90vw;
  }

  &__hidden-close {
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    clip: rect(1px, 1px, 1px, 1px);
    color: #000;
    display: none;
    font-size: 14px;
    height: 1px;
    letter-spacing: 0.25px;
    overflow: hidden;
    padding: 5px 7px;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 1px;
    z-index: -1;


    &:focus {
      clip: auto;
      height: auto;
      overflow: visible;
      width: auto;
      z-index: 100;
    }
  }

  &-wrapper {

    &.-open {

      .sidebar {
        transform: translateX(0);
      }

      .sidebar__close,
      .sidebar__hidden-close {
        display: block;
      }

      .sidebar__overlay {
        display: block;
      }
    }
  }

  &__overlay {
    bottom: 0;
    cursor: url('../images/hover-arrow-close.png') 32 32, auto;
    cursor: -webkit-image-set(url('../images/hover-arrow-close.png') 1x, url('../images/hover-arrow-close@2x.png') 2x) 32 32, pointer; /* Webkit */
    display: none;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
  }

  &__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: none;
    font-family: Graphik, sans-serif;
    font-size: 20px;
    font-weight: normal;
    padding: 0;
    position: absolute;
    right: 50px;
    top: 30px;

    @include small {
      border: none;
      display: block;
      position: fixed;
      right: 24px;
      top: 24px;
      width: 40px;
      z-index: 140;
    }

    &-image {
      width: 100%;
    }
  }

  &__content {
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    overflow: auto;
    padding: 0 45px;
    width: 100%;

    @include small {
      padding: 0 25px;
    }

    &-about {

      p {
        font-family: GTSuperText, serif;
        font-size: 36px;
        letter-spacing: -.2px;
        line-height: 44px;
        margin: 110px 0 34px;

        @include small {
          font-size: 26px;
          line-height: 30px;
          margin-top: 90px;
        }
      }

      strong {
        font-family: Graphik-Semibold, sans-serif;
      }

      span {
        font-family: Graphik, sans-serif;
        font-weight: normal;
        font-size: 35px;

        @include small {
          font-size: 25px;
        }
      }
    }

    &-line {
      background-color: black;
      border: none;
      height: 1px;

      @include small {
        display: none;
      }
    }

    &-bio {
      font-family: GTSuperText, serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      margin-bottom: 65px;
      margin-top: 37px;
      width: 100%;

      @include small {
        margin-top: 14px;
      }

      &-portrait {
        display: inline-block;
        width: 45%;

        @include small {
          width: 100%;
        }

        &-image {
          height: 100%;
          left: 0;
          object-fit: cover;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.3s ease-in-out;
          width: 100%;

          &.-loaded {
            opacity: 1;
          }

          &-wrapper {
            height: 0;
            margin-bottom: 14px;
            padding-bottom: 59.5%;
            position: relative;
            width: 100%;
          }
        }
      }

      &-text {
        display: inline-block;
        padding-left: 24px;
        vertical-align: top;
        width: 53%;

        @include small {
          margin-bottom: 50px;
          margin-top: 20px;
          padding-left: 0;
          width: 100%;
        }

        p {
          margin: 0;
        }

        strong {
          font-family: Graphik-Semibold, sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}
