@keyframes bodyFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

* {
  box-sizing: border-box;
}

::selection,
::-moz-selection {
  background-color: #eee; /* WebKit/Blink Browsers */
}

html,
body {
  margin: 0;
}

body {
  animation: bodyFadeIn 0.3s ease-in-out forwards 0.3s;
  display: flex;
  flex-flow: column;
  opacity: 0;

  &.-lock-scroll {
    overflow: hidden;
  }
}

.cms-preview {
  padding: 80px 0;

  &__divider {
    display: flex;
    justify-content: center;
    margin: 100px 0;
    width: 100%;

    &-line {
      background-color: gray;
      display: block;
      height: 1px;
      opacity: 0.5;
      width: 30%;
    }
  }

  .hero {
    height: auto !important;
    position: relative;
  }

  .hero__heading {
    animation-duration: 0s !important;
    margin-top: 0 !important;
    transform: translateY(0px) !important;
  }

  .sidebar__content {
    height: auto !important;

    p {
      margin-top: 0;
    }
  }

  .sidebar__content-bio {
    margin-bottom: 0;
  }

  .pillars {
    font-size: 0px;
  }

  .pillar__title,
  .pillar__title-line {
    position: relative !important;
    top: auto !important;
  }

  .pillar__title-inner {
    background-color: transparent !important;
    animation-duration: 0s !important;
    opacity: 1 !important;
    transform: translateY(0px) !important;
  }

  .pillar__cms-dscription {
    font-family: Graphik, sans-serif;
    font-size: 14px;
    text-align: center;
  }

  .pillar__content-column-image,
  .sidebar__content-bio-portrait-image {
    opacity: 1;
  }
}
