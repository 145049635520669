.header {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 90px;
  justify-content: space-between;
  padding: 0 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;

  @include small {
    border-bottom: 1px solid transparent;
    padding: 0 23px;
  }

  &__logo {
    display: block;
    font-family: Graphik-Semibold, sans-serif;
    font-size: 20px;
    font-weight: bolder;
    width: 186px;

    @include small {
      width: 163px;
    }

    &-image {
      display: block;
      width: 100%;
    }
  }

  &__skip-to {
    background-color: #f6f6f6;
    border-radius: 2px;
    clip: rect(1px, 1px, 1px, 1px);
    color: #000;
    display: table;
    font-size: 14px;
    height: 1px;
    left: 250px;
    letter-spacing: 0.25px;
    margin-left: 48px;
    overflow: hidden;
    padding: 24px;
    position: absolute;
    top: 9px;
    vertical-align: middle;
    white-space: nowrap;
    width: 1px;
    z-index: 100;

    &:focus {
      clip: auto;
      height: 46px;
      overflow: visible;
      width: auto;
    }
  }

  &__hidden-nav {
    clip: rect(1px, 1px, 1px, 1px);
    flex: 1;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    width: 1px;
    z-index: -1;

    &:focus,
    &:focus-within {
      clip: auto;
      height: auto;
      opacity: 1;
      overflow: visible;
      width: auto;
      z-index: 100;
    }


    &-list {
      display: flex;
      list-style: none;

      &-item {
        margin-right: 5px;

        &-button {
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid black;
          font-family: Graphik, sans-serif;
          font-size: 18px;
          font-weight: normal;
          padding: 0;
        }
      }
    }
  }

  &__sidebar-trigger {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid black;
    font-family: Graphik, sans-serif;
    font-size: 18px;
    font-weight: normal;
    padding: 0;

    @include small {
      font-size: 14px;
    }

    &:hover {
      cursor: url('../images/hover-arrow-open.png') 32 32, auto;
      cursor: -webkit-image-set(url('../images/hover-arrow-open.png') 1x, url('../images/hover-arrow-open@2x.png') 2x) 32 32, pointer; /* Webkit */
    }
  }
}