@font-face {
  font-family: GTSuperText;
  font-weight: bolder;
  font-display: swap;
  src: url(../../assets/fonts/GT-Super-Text-Bold.woff);
}

@font-face {
  font-family: GTSuperText;
  font-weight: bold;
  font-display: swap;
  src: url(../../assets/fonts/GT-Super-Text-Medium.woff);
}

@font-face {
  font-family: GTSuperText;
  font-weight: normal;
  font-display: swap;
  src: url(../../assets/fonts/GT-Super-Text-Book.woff);
}

@font-face {
  font-family: Graphik-Semibold;
  font-display: swap;
  src: url(../../assets/fonts/Graphik-Semibold.woff);
}

@font-face {
  font-family: Graphik;
  font-weight: normal;
  font-display: swap;
  src: url(../../assets/fonts/Graphik-Regular.woff);
}

@font-face {
  font-family: Graphik;
  font-weight: bold;
  font-display: swap;
  src: url(../../assets/fonts/Graphik-Medium.woff);
}