@keyframes pillarFadeIn {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.pillar {
  background-color: #fff;
  display: block;
  padding: 0 0 20px;
  position: relative;
  z-index: 3;

  &__back-cover {
    background: #fff;
    bottom: 0;
    display: block;
    height: 100px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 5;

    &.-hide {
      display: none;
    }
  }

  &__title {
    bottom: 0;
    display: flex;
    flex-flow: column;
    height: 45px; // This height is assumed and used inline as the bottom in pillar.html
    opacity: 1;
    position: sticky;
    top: 90px;
    width: 100%;
    z-index: 5;

    &:focus {
      outline: 0;
    }

    @include small {
      top: auto;
    }

    &-inner {
      align-items: center;
      animation: pillarFadeIn 1s cubic-bezier(0.81, 0, 0.23, 0.99) forwards;
      background-color: #fff;
      border-top-style: solid;
      border-top-width: 1px;
      display: flex;
      flex: 1;
      opacity: 0;
      width: 100%;
    }

    &.-in-middle {

      .pillar__title-inner {
        background-color: #fff;
        animation-duration: 0s !important;
        opacity: 1 !important;
        transform: translateY(0px) !important;
      }
    }

    &.-stuck-top {
      background-color: transparent;

      .pillar__title-inner {
        animation-duration: 0s !important;
        opacity: 1 !important;
        transform: translateY(0px) !important;
      }

      .pillar__title-heading {
        width: 20%;
      }

      &:before {
        background: #fff;
        content: "";
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        top: -3px;
        width: 100%;
      }

      .pillar__title-inner {
        background-color: transparent;
      }
    }

    &.-stuck-bottom {
      background-color: #fff;
      cursor: url('/assets/images/hover-arrow-up.png') 32 32, auto;
      cursor: -webkit-image-set(url('/assets/images/hover-arrow-up.png') 1x, url('/assets/images/hover-arrow-up@2x.png') 2x) 32 32, pointer; /* Webkit */

      .pillar__title-inner {
        background-color: #fff;
        border-top-style: solid;
        border-top-width: 1px;
        max-height: 45px;
      }

      .pillar__title-heading {
        background-color: transparent;
        width: 100%;
      }

      &:hover {

        .pillar__title-inner {
          margin-top: -10px;
          transition: margin-top .4s ease;

          @include small {
            margin-top: 0;
          }
        }
      }

      &:focus {
        .pillar__title-inner {
          margin-top: 0 !important;
          transition: none !important;
        }
      }
    }

    &-heading {
      background-color: #fff;
      font-family: Graphik, sans-serif;
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      padding-left: 50px;
      width: 100%;

      @include small {
        padding-left: 23px;
      }
    }
  }

  &__content {
    margin-left: 20%;
    margin-right: 20px;

    @include small {
      margin-left: 23px;
      margin-right: 23px;
    }

    &-intro {
      display: flex;
      flex-flow: column;
      font-family: GTSuperText, serif;
      font-size: 38px;
      font-weight: normal;
      margin: 51px 0 60px;
      padding-right: 65px;

      @include small {
        font-size: 18px;
        letter-spacing: -.1px;
        line-height: 24px;
        margin: 45px 0 60px;
        padding-right: 0;
      }
    }

    &-eyebrow {
      display: block;
      font-family: Graphik, sans-serif;
      font-size: 15px;
      font-weight: bold;
      margin: 60px 0 26px;
    }

    &-columns {
      display: flex;
      flex-wrap: wrap;
    }

    &-column {
      display: flex;
      flex-flow: column;
      margin-bottom: 80px;
      padding-right: 45px;
      vertical-align: top;
      width: 49%;

      @include small {
        padding-right: 0;
        width: 100%;
      }

      &-link {
        cursor: url('../images/hover-arrow-link.png') 32 32, auto;
        cursor: -webkit-image-set(url('../images/hover-arrow-link.png') 1x, url('../images/hover-arrow-link@2x.png') 2x) 32 32, pointer; /* Webkit */
        text-decoration: none;
        width: 100%;
      }

      &-image {
        height: 100%;
        left: 0;
        object-fit: cover;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        width: 100%;

        &.-loaded {
          opacity: 1;
        }

        &-wrapper {
          height: 0;
          margin-bottom: 14px;
          padding-bottom: 58.4%;
          position: relative;
          width: 100%;
        }
      }

      &-title {
        border-bottom: 1px solid black;
        color: #000;
        display: inline;
        font-family: GTSuperText, serif;
        font-size: 26px;
        font-weight: bolder;
        line-height: 35px;
        margin: 24px 0;
        text-decoration: none;
      }

      &-subtitle {
        color: #FDBB15;
        margin-top: 24px;
        font-family: Graphik, sans-serif;
        font-size: 16px;
        font-weight: bold;
      }

      &-description {
        font-family: Graphik, serif;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: -.15px;
        line-height: 24px;
        margin: 8px 0 30px;
      }
    }
  }
}