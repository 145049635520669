@mixin xsmall() {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin small() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin medium() {
  @media only screen and (min-width: 768px) and (max-width: 1440px) {
    @content;
  }
}