.footer {
  align-items: center;
  background-color: white;
  border-top: 1px solid #E2E2E2;
  display: flex;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  height: 300px;
  width: 100%;

  &__content {
    align-items: flex-start;
    display: flex;
    height: 100px;
    width: 100%;

    @include small {
      flex-flow: column;
      height: auto;
    }
  }

  &__column {
    padding: 0 3%;

    @include small {
      margin: 18px 0;
      width: 100%;
    }

    &--copyright {
      line-height: 20px;
      width: 25%;

      @include small {
        width: 100%;
      }
    }

    &--about {
      font-family: GTSuperText, serif;
      font-size: 14px;
      line-height: 20px;
      width: 26%;

      @include small {
        width: 100%;
      }

      p {
        margin: 0;
      }

      strong {
        font-family: Graphik-Semibold, sans-serif;
      }
    }

    &--links {
      line-height: 20px;
      width: 44%;

      @include small {
        width: 100%;
      }
    }

    &-pillar-link {
      background-color: transparent;
      border: 0;
      display: inline-block;
      font-family: Graphik, sans-serif;
      margin-bottom: 14px;
      font-size: 14px;
      margin-right: 14px;
      padding: 0 0 2px 0;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }
  }
}


.footer-content-wrapper {
  height: auto;
}
